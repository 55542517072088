// @charset "utf-8";

// /* initialize */
// body {
//   color: #fff;
//   font-family: Helvetica sans-serif, "微软雅黑";
//   font-size: 14px;
//   background: #3a3a3a;
//   line-height: 1.5;
// }
// a {
//   text-decoration: none;
// }
// input {
//   -webkit-appearance: none;
//   outline: none;
// }
.clear {
  clear: both;
}
/* initialize */

/* invitation-container */
.invitation-container {
  background: #222;
}
.invitation-container .msg-box {
  position: relative;
  padding: 5% 8%;
}
.invitation-container .msg-box:before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 6%;
  bottom: -36px;
  border-style: solid;
  border-width: 18px 26px;
}
.invitation-container .msg-box:after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 6%;
  bottom: -36px;
  border-style: solid;
  border-width: 18px 5px;
  border-color: #222 transparent transparent #222;
}
.invitation-container .share {
  background: -webkit-radial-gradient(50% 5%, #a0e000 10%, #65c300);
}
.invitation-container .share:before {
  border-color: #66c400 transparent transparent #66c400;
}
.invitation-container .share h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  padding: 10px 0;
  text-shadow: 0 0 15px rgba(106, 177, 8, 0.75);
}
.invitation-container .share h3 {
  text-align: right;
}
.invitation-container .save {
  background: -webkit-radial-gradient(50% 5%, #f43f00 10%, #ea0100);
}
.invitation-container .save:before {
  border-color: #ea0100 transparent transparent #ea0100;
}
.invitation-container .save h2 {
  text-align: center;
  font-size: 120px;
  font-weight: 700;
  padding: 10px 0;
  text-shadow: 0 0 15px rgba(255, 150, 0, 0.75);
}
.invitation-container .msg {
  padding: 40px 5% 30px 5%;
  font-size: 18px;
  line-height: 30px;
}
.invitation-container .btn {
  display: block;
  margin: 0 auto;
  text-align: center;
  height: 46px;
  line-height: 46px;
  background: #11b0ea none repeat scroll 0 0;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
}
.invitation-container .btn-fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 15px 0;
  background: #eaeaea;
}
.invitation-container .btn-white {
  display: block;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  height: 46px;
  line-height: 46px;
  background: #60ca2c;
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
}
.invitation-container .intro {
  padding: 40px 5%;
}
.invitation-container .intro img {
  width: 100%;
  margin: 10px 0;
}
.invitation-container .intro h1 {
  font-size: 18px;
  line-height: 28px;
}
.invitation-container .intro h2 {
  line-height: 24px;
  background: rgb(146, 208, 80);
  color: #333;
  margin: 10px 0;
}
.invitation-container .intro h3 {
  line-height: 24px;
  color: rgb(146, 208, 80);
  margin: 10px 0;
}
.invitation-container .intro p {
  font-size: 12px;
  line-height: 20px;
  text-indent: 24px;
  margin-bottom: 5px;
}
.invitation-container .intro strong {
  font-weight: 700;
  font-size: 14px;
}
.invitation-container .intro span {
  color: #ff4957;
}
.invitation-container .intro .list {
  margin: 10px 0;
  font-size: 12px;
  list-style: disc inside;
}
.invitation-container .intro .list li {
  line-height: 20px;
  margin-left: 10px;
}
// .invitation-bottom-bar {
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   height: 32px;
//   background: url(../images/invitation-bottom-bar.png) no-repeat;
//   background-size: 100% 100%;
// }
.invitation-container .apply {
  padding: 20px 5% 60px 5%;
}
.invitation-container .apply h1 {
  font-size: 16px;
  padding: 10px 0;
}
.invitation-container .apply h2 {
  padding: 20px 0;
}
.invitation-container .apply input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #666;
  background: #222;
  color: #fff;
}
.invitation-container .apply textarea {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  margin-bottom: 30px;
  border: 1px solid #666;
  background: #222;
  color: #fff;
}
.invitation-container .apply select {
  width: 40%;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #666;
  background: #222;
  color: #fff;
}
/* invitation-container */
