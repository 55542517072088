.tipsText {
  font-size: 14px;
  color: #e6e6e6;
  line-height: 20px;
  padding: 0 24px;
}
.titImg {
  padding: 25px 44px;
}
.titImg img {
  display: block;
  width: 100%;
}
.mdxhb {
  padding: 25px 0;
  text-align: center;
}
.mdxhb span {
  font-size: 12px;
  color: #999;
  vertical-align: middle;
}
.mdxhb a {
  font-size: 16px;
  color: #f8e71c;
  vertical-align: middle;
  padding-left: 10px;
}
.mdxhb a img {
  width: 18px;
  margin-left: 10px;
  vertical-align: middle;
}
.inviteShare {
  width: 300px;
  height: 160px;
  background: url("../../assets/bg.png") no-repeat center center;
  background-size: 100% 100%;
  text-align: center;
  margin: 20px auto;
}
.inviteShare p {
  font-size: 18px;
  color: #333;
  padding-top: 20px;
}
.inviteShare h2 {
  font-size: 48px;
  color: #ff748e;
  line-height: 1;
  padding: 10px 0 25px;
}
.inviteShare h3 {
  font-size: 12px;
  color: #999999;
  font-weight: normal;
}

.invitation-container {
  background-color: #3a3a3a;
  min-height: 100vh;
}

.invitation-container .btn {
  background: #f8e71c;
  font-size: 16px;
  color: #222222;
  margin: 30px 10px 0;
  line-height: 46px;
}
