@media screen and (max-width: 767px) {
  .mobile-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 340px;
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 99;

    .title {
      width: 60px;
      height: 17px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .menu {
      position: absolute;
      top: 13px;
      left: 12px;
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mob-download {
      position: absolute;
      top: 9px;
      right: 12px;
      width: 80px;
      height: 32px;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: #FFD630;

      .link {
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        color: #1D2126;
        cursor: pointer;
      }
    }

    .down-menu {
      position: absolute;
      left: 0;
      top: 50px;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 32px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        line-height: 48px;
        cursor: pointer;

        .to {
          font-size: 16px;
          color: #81858c;
          text-decoration: none;
        }

        .active {
          font-size: 16px;
          color: #191F25;
        }
      }

      .phone {
        margin-top: 12px;
        font-size: 12px;
        color: #4f94ff;
      }
    }
  }

  .nav {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nav {
    .navbar {
      min-width: auto;
      max-width: auto;
      .tel {
        display: none;
      }
    }
  }
}