@media screen and (max-width: 767px) {
  .future-slider {
    .slick-slide:not(.slick-center) {

      .content {
        transform: scale(.8);
      }
    }
  }

  .future {
    min-width: 340px;
    padding-top: 30px;
    padding-bottom: 35px;
    background: url('../../../assets/future_media@2x.png') center / cover no-repeat;

    .mobile-content {
      width: 100%;
      display: block;
      margin-top: 32px;
      background-color: #f7f8f9;

      .card-wrapper {
        padding: 0 2.5px;
      }

      .content {
        height: 240px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(29, 37, 47, 0.08);
        position: relative;
        transition: all .2s;

        .model {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;

          .display {
            padding-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .top {
              width: 56px;
              height: 56px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .bottom {
              margin-top: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #1d2126;
              }

              .sub {
                margin-top: 8px;
                font-size: 14px;
                color: #81858c;
              }
            }
          }

          .link {
            width: 100px;
            height: 32px;

            .link-to {
              display: block;
              width: 100%;
              height: 100%;
              color: #1d2126;
              background-color: #ffd630;
              line-height: 32px;
              text-align: center;
              font-size: 12px;
              font-weight: 500;
              text-decoration: none;
            }
          }
        }

        &::after {
          height: 2px;
          width: 100%;
          border-radius: 0 0 4px 4px;
          background-color: #ffd630;
          position: absolute;
          bottom: 0px;
          content: '';
        }
      }
    }

    .fu-content {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .future {
    margin: 0 auto;
    .mobile-content {
      display: none;
    }
    .fu-content {
      display: block;
      display: flex;
      margin-top: 96px;
      .content {
        margin: 0 8px;
      }
    }
  }
}