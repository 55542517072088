.notice {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 100px;
  background: url('../../../assets/notice@2x.png') center / cover no-repeat;

  .mob-title {
    color: #fff;
  }

  .top-title {
    margin-top: 24px;
    font-size: 20px;
    color: #fff;
  }

  .not-content {
    margin-top: 100px;
    margin-bottom: 46px;
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;

      img {
        width: 80px;
        height: 80px;
      }

      .name {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #FFD630;
        line-height: 20px;
      }
    }
  }
}

.des {
  display: flex;
  justify-content: center;

  .title {
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
    line-height: 36px;
  }
}