@media screen and (max-width: 767px) {
  .banner {
    position       : relative;
    min-width      : 340px;
    height         : 400px;
    background     : url(../../../assets/media_banner@2x.png) no-repeat;
    background-size: 100% 100%;

    .i-banner {
      width         : 375px;
      padding-top   : 165px;
      padding-bottom: 0;
      text-align    : center;

      img {
        width : 295px;
        height: 235px;
      }
    }

    .mark {
      position      : absolute;
      top           : 0;
      left          : 0;
      height        : 100%;
      padding-top   : 89px;
      padding-bottom: 134px;

      .mark-container {
        width      : 295px;
        display    : flex;
        align-items: center;

        .top {
          p {
            font-size    : 24px;
            line-height  : 1;
            margin-bottom: 0;

            &:nth-child(1) {
              margin-bottom: 8px;
            }
          }
        }

        .bottom {
          display: none;
        }

        .mob-bottom {
          display    : block;
          margin-left: 16px;

          .btn {
            width           : 80px;
            height          : 32px;
            border-radius   : 2px;
            background-color: transparent;
            border          : 1px solid #1D2126;
            outline         : none;

            .link {
              font-size      : 12px;
              font-weight    : bold;
              color          : #1D2126;
              display        : block;
              text-align     : center;
              line-height    : 32px;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .banner {
    margin: 0 auto;

    .i-banner {
      width: 886px;

      img {
        width: 547px;
      }
    }

    .mark {
      width         : 100%;
      height        : 100%;
      padding-top   : 191px;
      padding-bottom: 221px;

      .mark-container {
        width : 728px;
        margin: 0 auto;

        .top {
          p {
            font-size    : 48px;
            color        : #1D2126;
            line-height  : 56px;
            margin-bottom: 12px;
          }
        }

        .mob-bottom {
          display: none;
        }

        .bottom {
          display   : block;
          margin-top: 52px;
          display   : flex;

          .dask {
            width        : 128px;
            margin-right : 20px;
            border       : 1px solid #1D2126;
            border-radius: 4px;

            .link {
              display        : block;
              height         : 40px;
              text-align     : center;
              font-size      : 16px;
              font-weight    : 600;
              line-height    : 40px;
              color          : #1D2126;
              text-decoration: none;
              cursor: pointer;
            }
          }

          .e-wrap {
            position: relative;

            .phone {
              width        : 128px;
              height       : 40px;
              font-size    : 16px;
              color        : #1D2126;
              font-weight  : 600;
              line-height  : 40px;
              text-align   : center;
              border       : 1px solid #1D2126;
              border-radius: 4px;
              cursor       : pointer;
            }

            .e-code {
              position : absolute;
              top      : -200px;
              left     : 50%;
              transform: translateX(-50%);
              display  : none;

              img {
                width : 200px;
                height: 200px;
              }
            }

            &:hover {
              .e-code {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}