* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}
a {
  background-color: transparent;
  text-decoration: none;
}
.offline-tip {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba($color: #000, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .tip-box {
    margin: 30px;
    width: 600px;
    height: 580px;
    min-width: 300px;
    background-color: #fff;
    border-radius: 8px;
    z-index: 101;
    .tip-top {
      position: relative;
      width: 100%;
      height: 63px;
      background-color: #FAFAFA;
      .tip-title {
        height: 100%;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tip-content {
      padding: 30px;
      height: calc(100% - 140px);
      overflow-y: scroll;
      overscroll-behavior: contain;
      p {
        margin-top: 14px;
        line-height: 26px;
        text-indent: 2em;
      }
      .import-content {
        margin-top: 30px;
        margin-bottom: 30px;
        >p {
          margin-top: 0px;
        }
      }
      .text-right {
        text-align: right;
      }
      .font-weight {
        font-weight: 600;
      }
    }
    .tip-bottom {
      height: 77px;
      display: flex;
      justify-content: center;
      align-items: center;
      .tip-close {
        height: 40px;
        width: 164px;
        line-height: 40px;
        border-radius: 20px;
        cursor: pointer;
        background-color: #F5D33F;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .countdown {
        background-color: rgba($color: #F5D33F, $alpha: 0.5);
        cursor: default;
      }
    }

  }
}
