.future {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 120px;
  background: url('../../../assets/news_report@2x.png') center / cover no-repeat;

  .mobile-content {
    display: none;
  }

  .fu-content {
    display: block;
    display: flex;
    margin-top: 96px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 236px;
      height: 285px;
      margin: 0 32px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 2px 8px 0px rgba(29, 37, 47, 0.08);

      .model {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;

        .display {
          padding-bottom: 65px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .top {
            width: 64px;
            height: 64px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .bottom {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
              font-size: 16px;
              font-weight: 500;
              color: #1d2126;
              line-height: 16px;
              margin-bottom: 8px;
            }

            .sub {
              font-size: 16px;
              color: #81858c;
              line-height: 16px;
            }
          }
        }

        .link {
          line-height: 16px;
          cursor: pointer;

          .tolink {
            text-decoration: none;
            font-size: 16px;
            color: #4f94ff;
          }
        }
      }

      .line {
        height: 4px;
        border-radius: 0 0 8px 8px;
        background-color: #4f94ff;
      }
    }
  }
}