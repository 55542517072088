.tips {
  position: fixed;
  right: 16px;
  bottom: 16px;

  .clear {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.clear-tip {
  display: none;
}

@media screen and (max-width: 767px) {
  .tips {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
   .tips {
     display: none;
   }
}