@media screen and (max-width: 767px) {
  .cooperation {
    min-width: 340px;
    padding-top: 30px;
    padding-bottom: 50px;

    .coo-content {
      display: flex;
      flex-wrap: wrap;
      width: 340px;
      margin-top: 24px;

      .content {
        width: 75px;
        height: 40px;
        margin: 0 5px 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .cooperation {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 100px;
  
    .coo-content {
      display: flex;
      flex-wrap: wrap;
      width: 680px;
      margin-top: 84px;
  
      .content {
        width: 150px;
        height: 80px;
        margin: 0 10px 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}