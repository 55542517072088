@media screen and (max-width: 767px) {
  .protect {
    width: 100%;
    min-width: 340px;

    .placeholder {
      height: 50px;
    }

    .content-wrap {
      padding: 30px 16px;
      width: auto;

      .title {
        display: none;
      }

      .mob-wrap {
        display: block;
        margin-bottom: 30px;
        height: 27px;
        position: relative;

        .mob-title {
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          color: #1d2126;
        }

        .line {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 28px;
          height: 3px;
          background-color: #f2c121;
        }
      }

      h5 {
        font-size: 12px;
        font-weight: 400;
        color: #1d2126;
        line-height: 28px;
        letter-spacing: 0.5px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: #1d2126;
        line-height: 22px;
        letter-spacing: 0.5px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .protect {
    width: 100%;
  
    .content-wrap {
      width: 728px;
      padding: 80px 0 100px;
    }
  }
}