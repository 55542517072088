.homework {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 120px;
  background: url('../../../assets/news_report@2x.png') center / cover no-repeat;

  .home-content {
    margin-top: 112px;
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 64px;

      img {
        width: 80px;
        height: 80px;
      }

      .name {
        margin-top: 34px;
        font-size: 20px;
        font-weight: 500;
        color: #1d2126;
        text-align: center;
      }
    }
  }
}