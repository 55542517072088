.worktime {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 140px;
  background-color: #fff;

  .content {
    margin-top: 96px;
    text-align: center;
    line-height: 44px;

    .pc_src {
      width: 100%;
      height: 44px;
    }

    .mob {
      display: none;
    }
  }
}