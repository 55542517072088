@media screen and (max-width: 767px) {
  .communicate {
    min-width: 340px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 40px;
    background: url('../../../assets/media_com@2x.png') center / cover no-repeat;

    .com-content {
      width: 344px;
      margin: 19px auto 28px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 132px;
        margin: 31px 20px 0;

        img {
          width: 40px;
          height: 40px;
        }

        .name {
          width: 100%;
          height: 32px;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
          }
        }
      }
    }

    .xiaoheiban {
      display: flex;
      justify-content: center;

      .content {
        .src {
          width: 17px;
          height: 16px;
        }

        .title {
          font-size: 14px;
          color: #1d2126;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .communicate {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 100px;
    background: url('../../../assets/news_report@2x.png') center / cover no-repeat;

    .com-content {
      width: 628px;
      margin: 96px auto 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 220px;
        margin: 0 47px 56px;
  
        img {
          width: 80px;
          height: 80px;
        }
  
        .name {
          width: 100%;
          height: 56px;
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          span {
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #1d2126;
            line-height: 28px;
          }
        }
      }
    }
  }
}