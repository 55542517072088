.banner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 632px;
  background: url('../../../assets/banner@2x.png') no-repeat;
  background-size: 100% 100%;

  .i-banner {
    text-align: right;
    margin: 0 auto;
    padding-top: 186px;
    padding-bottom: 11px;
    width: 960px;
    img {
      width: 547px;
      height: 435px;
    }
  }

  .mark {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 191px;
    padding-bottom: 221px;

    .mark-container {
      width: 840px;
      margin: 0 auto;
      .top {
        p {
          font-size: 48px;
          color: #1D2126;
          line-height: 56px;
          margin-bottom: 12px;
        }
      }
  
      .mob-bottom {
        display: none;
      }
  
      .bottom {
        display: block;
        margin-top: 52px;
        display: flex;
  
        .dask {
          width: 128px;
          margin-right: 20px;
          border: 1px solid #1D2126;
          border-radius: 4px;
  
          .link {
            display: block;
            height: 40px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 40px;
            color: #1D2126;
            text-decoration: none;
            cursor: pointer;
          }
        }
  
        .e-wrap {
          position: relative;
  
          .phone {
            width: 128px;
            height: 40px;
            font-size: 16px;
            color: #1D2126;
            font-weight: 600;
            line-height: 40px;
            text-align: center;
            border: 1px solid #1D2126;
            border-radius: 4px;
            cursor: pointer;
          }
  
          .e-code {
            position: absolute;
            top: -200px;
            left: 50%;
            transform: translateX(-50%);
            display: none;
  
            img {
              width: 200px;
              height: 200px;
            }
          }
  
          &:hover {
            .e-code {
              display: block;
            }
          }
        }
      }
    }
  }
}