@media screen and (max-width: 768px) {
  .worktime {
    min-width: 340px;
    display: flex;
    height: 175px;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: #fff;

    .content {
      margin-top: 36px;
      text-align: center;
      line-height: 22px;

      .pc_src {
        display: none;
      }

      .mob {
        display: block;

        .mob_src {
          width: 330px;
          height: 22px;
        }
      }
    }
  }
}