.cooperation {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 100px;

  .coo-content {
    display: flex;
    flex-wrap: wrap;
    width: 1020px;
    margin-top: 96px;

    .content {
      width: 150px;
      height: 80px;
      margin: 0 10px 20px;

      .link-media {
        display: block;
        text-decoration: none;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}