@media screen and (max-width: 767px) {
  .notice {
    min-width: 340px;
    height: 297px;
    padding-top: 30px;
    padding-bottom: 50px;
    background: url('../../../assets/media_notice@2x.png') center / cover no-repeat;

    .mob-title {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    .mob-line {
      width: 28px;
      height: 3px;
    }

    .top-title {
      margin-top: 12px;
      font-size: 12px;
      color: #fff;
    }

    .not-content {
      margin-top: 45px;
      margin-bottom: 24px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        margin-right: 59px;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 41px;
          height: 40px;
        }

        .name {
          margin-top: 7px;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          color: #FFD630;
          line-height: 16px;
        }
      }
    }
  }

  .des {
    display: flex;
    justify-content: center;

    .title {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      line-height: 18px;
    }
  }
}