@media screen and (max-width: 767px) {
  .homework {
    min-width: 340px;
    padding-top: 30px;
    padding-bottom: 41px;
    background: url('../../../assets/media_home@2x.png') center / cover no-repeat;

    .home-content {
      margin-top: 48px;
      display: flex;

      .content {
        margin: 0 24px;

        img {
          width: 41px;
          height: 41px;
        }

        .name {
          margin-top: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #1d2126;
          text-align: center;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .homework {
    padding-top: 60px;
    padding-bottom: 120px;
    background: url('../../../assets/media_home@2x.png') center / cover no-repeat;

    .home-content {
      margin-top: 112px;
      display: flex;

      .content {
        margin: 0 28px;

        img {
          width: 80px;
          height: 80px;
        }
  
        .name {
          margin-top: 34px;
          font-size: 20px;
          font-weight: 500;
          color: #1d2126;
          text-align: center;
        }
      }
    }
  }
}