.record {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  background: url('../../../assets/homework_bg@2x.png') center / cover no-repeat;

  .sub-title {
    font-size: 20px;
    text-align: center;
    color: #1d2126;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 100px;
  }

  .i-record {
    width: 834px;
    height: 438px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}