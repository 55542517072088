@media screen and (max-width: 767px) {
  .record {
    min-width: 340px;
    padding-top: 30px;
    background: url('../../../assets/media_grow@2x.png') center / cover no-repeat;

    .sub-title {
      font-size: 12px;
      line-height: 16px;
      padding: 0 68px;
      margin-top: 12px;
      margin-bottom: 50px;
    }

    .i-record {
      width: 343px;
      height: 180px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .record {
    .sub-title {
      width: 400px;
      line-height: 28px;
    }

    .i-record {
      width: 834px;
      height: 438px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
  
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
}