.communicate {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 102px;
  background: url('../../../assets/news_report@2x.png') center / cover no-repeat;

  .com-content {
    width: 960px;
    margin: 96px auto 74px;
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 180px;
      margin: 0 30px;

      img {
        width: 80px;
        height: 80px;
      }

      .name {
        width: 100%;
        height: 56px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #1d2126;
          line-height: 28px;
        }
      }
    }
  }

  .xiaoheiban {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      align-items: center;

      .src {
        width: 32px;
        height: 32px;
      }

      .title {
        font-size: 28px;
        font-weight: 500;
        color: #1d2126;
        margin-right: 8px;
      }
    }
  }
}